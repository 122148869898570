%top-aligned {
    justify-content: start;
}

%bottom-aligned{
    justify-content: end;
}

%right-aligned{
    left: auto;
    right: 0;
    text-align: right;

    h2 {
        padding: 1em 4vw 1em 15vw;
    }
}

.panel .copy {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh - ($down-arrow-size + 2 * $down-arrow-spacing);
    z-index: 5;
    width: 80vw;
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo{
        background-image: url("../images/logos/pups_green_628x875.png");
        height: 40vh;
        flex: 0 0 auto;
    }

    p {
        margin: 0;
        padding: 1em 4vw;
        flex: 0 0 auto;
    }

    h2 {
        margin: 0;
        padding: 1em 15vw 1em 4vw;
        flex: 0 0 auto;
        color: $text-highlight-color;

        strong {
            font-weight: 600;
            font-size: 120%;
        }
    }

    @include landscape-only {
        font-size: 2vw;
    }

    @include tablet-or-bigger {
        width: 60vw;
    }

    @include desktop-or-bigger {
        width: 50vw;
        justify-content: space-evenly;
    }
}

.panel.intro .copy{
    @extend %bottom-aligned;
}

.panel.storyboards .copy{
    @extend %top-aligned;
}

.panel.goods .copy{
    @extend %top-aligned;
    @extend %right-aligned;
}

.panel.content-made .copy{
    @extend %bottom-aligned;
    @extend %right-aligned;
}

.panel.boutique .copy{
    @extend %bottom-aligned;
}
