#hamburger-toggle {
    display: none;
}

.hamburger-icon {
    height: 30px;
    width: 40px;
    align-self: center;
    z-index: 3;

    .hamburger-bars {
        position: relative;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        span {
            position: absolute;
            height: 6px;
            width: 100%;
            background: $text-highlight-color;
            transition-property: transform;
            transition-duration: 0.2s;

            body.hamburger-menu-open &{
                //background: blue;
            }
        }

        #one {
            top: 0;
            body.hamburger-menu-open &{
                top: 12px;
                transform: rotateZ(-45deg);
            }
        }

        #two {
            top: 12px;
            body.hamburger-menu-open &{
                transform: rotateZ(-45deg);
                box-shadow: none;
            }
        }

        #three {
            bottom: 0;
            body.hamburger-menu-open &{
                bottom: 12px;
                transform: rotateZ(45deg);
            }
        }
    }
}

.hamburger-menu {
    position: fixed;
    top: 115px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100vh;
    max-width: 100vw;
    z-index: 2;
    padding: 0 2em;
    display: none;
    background-color: white;

    body.hamburger-menu-open &{
        display: block;
    }

    nav {
        //margin-top: 1em;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (orientation: landscape) {
            flex-direction: row;
            justify-content: center;
        }

        li {
            margin: 1em;

            @include mobile-or-smaller {
                margin: 1em 0.5em;
            }

            @media (orientation: landscape) {
                margin: 2em;
            }
        }

        a {
            //color: $purple-font;
        }

        h3 { 
            margin: 0.5em;
        }
    }

    .horizontal-line {
        margin: 1em auto 2em auto;
    }

    .hamburger-jobs-button {
        background-color: #A1FBEC;
        padding: 0.8em 1em 0.6em 1em;
        transition: 0.1s ease-in-out;
        margin-top: 0.5em;

        li {
            margin: 0.5em;
        }

        &:hover {
            filter: saturate(1.5);
            transition: 0.1s ease-in-out;
        }

        @media (orientation: landscape) {
            margin-top: 0;
        }
    }
}