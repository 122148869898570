

.down-arrow{
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 7;
    text-decoration: none;
    display: inline-block;
    border-radius: ($down-arrow-size) ($down-arrow-size) 0 0;
    width: $down-arrow-width;
    height: $down-arrow-size;
    margin: 0 0 0 (-0.5 * $down-arrow-width);
    color: $down-arrow-panel-bg-color;
    background: $text-highlight-color;
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: none;
    animation-name: pulse-inverse;

    &,
    i{
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-delay: 3s;
    }

    i{
        display: block;
        width: $down-arrow-icon-size;
        height: $down-arrow-icon-size;
        background: transparent;
        margin: auto;
        margin-top: $down-arrow-icon-margin;
        text-indent: -10000px;
        border-style: solid;
        border-width: ($down-arrow-icon-size / 2);
        border-color: $dark-text-color transparent transparent transparent;
        animation-name: icon-push;
    }

    .panel &{
        color: $background-color;
        background: $down-arrow-panel-bg-color;
        animation-name: pulse;

        i {
            border-color: $background-color transparent transparent transparent;
        }
    }
}

@keyframes icon-push {
    0% {
        margin-top: $down-arrow-icon-margin;
    }

    10% {
        margin-top: $down-arrow-icon-pushed-margin;
    }

    20% {
        margin-top: $down-arrow-icon-margin;
    }
}

@keyframes pulse-inverse {
    0% {
        box-shadow: $down-arrow-pulse-color 0 0 0;
    }

    10% {
        box-shadow: $down-arrow-pulse-color 0 0 0 (0.2 * $down-arrow-size);
    }

    20% {
        box-shadow: $down-arrow-pulse-color 0 0 0;
    }
}

@keyframes pulse {
    0% {
        box-shadow: $down-arrow-panel-pulse-color 0 0 0;
    }

    10% {
        box-shadow: $down-arrow-panel-pulse-color 0 0 0 (0.2 * $down-arrow-size);
    }

    20% {
        box-shadow: $down-arrow-panel-pulse-color 0 0 0;
    }
}
