.content-panels {
    display: flex;
    flex-direction: row;
    height: auto;

    @include tablet-or-smaller {
        flex-direction: column;
        height: auto;
    }

     li {
         //background-color: pink;

         h2 {
             max-width: 400px;
         }

         p {
             font-family: 'Roboto', sans-serif;
             font-size: 18px;
             max-width: 400px;
             margin-top: 1.5em;
         }
     }
}

.text-panel {
    background-color: white;
    color: $dark-text-color;
    //padding: 10%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 1em;

    @include tablet-or-smaller {
        width: 100%;
        padding: 3em 2em;
    }

    @include mobile-or-smaller {
        padding: 2em;
    }

    .nav-logo {
        height: 110px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;

        @include tablet-or-smaller {
            height: 90px;
        }
    }

    .horizontal-line {
        margin-left: 2em;

        @include tablet-or-smaller {
            margin: 2em 0;
            width: 30vw;
        }

        @include mobile-or-smaller {
            margin: 1em 0;
        }
    }
}

#intro {
    height: 500px;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            //width: 100%;
        }

        p {
            margin-top: 0.5em;
        }

        /*.horizontal-line {
            margin-left: 0;
            width: 100%;
        }*/
    }

    @include tablet-or-smaller {
        height: auto;
    }
}

#intro .text-panel {
    text-align: center;

    @include mobile-or-smaller {
        padding: 2em;
    }
}

#intro .image-panel {
    @include tablet-or-smaller {
        display: none;
    }
}

#third {
    @include tablet-or-smaller {
        flex-direction: column-reverse;
    }
}

.image-panel {
    width: 50%;

    @include tablet-or-smaller {
        width: 100%;
        height: 50vh;
    }
}

.paragraph {
    margin: 2em;

    @include tablet-or-smaller {
        margin: 2em 0;
    }
}

#trio-shot {
    background: url("../images/panels/bg_2000x1000_5.jpg") no-repeat 50% 50%;
    background-size: cover;
    background-position: right;
}

#dropbear-shot {
    background: url("../images/panels/bg_2000x1000_4.jpg") no-repeat 50% 50%;
    background-size: cover;
    background-position: left;
}

#kerry-shot {
    background: url("../images/panels/bg_1998x1080.jpg") no-repeat 50% 50%;
    background-size: cover;
    background-position: center;
}