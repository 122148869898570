.panels{
    .mask{
        @extend %panel;
        z-index: 3;
        position: fixed;
        overflow: hidden;
        pointer-events: none;
    }

    .mask-inner{
        position: absolute;
        background: url("../images/panels/mask_1920x1080.svg") no-repeat 50% 50%;
        background-size: 100% 100%;
        width: 130vmax;
        height: 130vmax;
        left: 50vw;
        top: 50vh;
        margin: -65vmax 0 0 -65vmax;
    }
}
