.panel{
    .background{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &.intro{
        .background{
            background-image: url("../images/panels/bg_2000x1000_2.jpg");
            background-position: top right;
        }
    }

    &.storyboards{
        .background{
            background-image: url("../images/panels/bg_2000x1000_5.jpg");
            background-position: bottom right;
        }
    }

    &.goods{
        .background{
            background-image: url("../images/panels/bg_2000x1000_4.jpg");
            background-position: bottom left;
        }
    }

    &.content-made{
        .background{
            background-image: url("../images/panels/bg_2000x1000_6.jpg");
            background-position: top left;
        }
    }

    &.boutique{
        .background{
            background-image: url("../images/panels/bg_1998x1080.jpg");
            background-position: top right;
        }
    }
}
