header{
    @extend %panel;
    background: $header-background-color;

    @include mobile-or-smaller {
        height: 50vh;
    }

    .logo{
        position: relative;
        height: 70vh;
        width: 90vw;
        margin: 0 auto;
        top: 10vh;
        opacity: 0;

        &.appear{
            opacity: 1;
            animation-name: header-logo-pop;
            animation-duration: 0.3s;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
        }
    }

    .banner-image {
        background: transparent url("../images/panels/bg_1920x1080.jpg") no-repeat 50% 50%;
        background-size: cover;
        position: relative;
        background-position: center 120px;
        height: 100vh;

        @include mobile-or-smaller {
            background-position: center 118px;
            height: 50vh;
        }
    }
}

.banner-image {
    background: transparent url("../images/panels/bg_2000x947.jpg") no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    height: 90vh;
    width: 100vw;
    margin: 0 auto;
    background-position: center 0;

    @include mobile-or-smaller {
        height: 50vh;
    }
}

@keyframes header-logo-pop {
    0% {
        transform: scale(0.2);
    }
    100% {
        transform: scale(1);
    }
}
