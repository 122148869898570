.nav-bar {
    background-color: white ;
    height: 120px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 10;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;

    .nav-content {
        display: flex;
        justify-content: space-between;
        margin: 0 5vw;
        height: 100%;
        align-items: center;

        @include mobile-or-smaller {
            //justify-content: space-around;
            justify-content: center;
        }
    }

    ul {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include mobile-or-smaller {
            //display: none;
        }
    }

    li {
        color: black;
    }

    a {
        text-decoration: none;
        color: $dark-text-color;
    }

    .nav-links {
        li {
            margin-left: 3em;
            transition: 0.1s ease-in-out;

            @include mobile-or-smaller {
                margin-left: 0;
            }

            a:hover {
                color: black;
            }
        }
    }

    .nav-logo {
        width: 220px;
        height: 70px;
        background-repeat: no-repeat;

        @include tablet-or-smaller {
            width: 160px;
            height: 50px;
        }

        @include mobile-or-smaller {
            width: 51vmin;
            height: 16vmin;
        }
    }
}

.nav-logo {
    background-image: url("../images/logos/pups_902x282.png");
    background-size: contain;
}

.jobs-button {
    background-color: #A1FBEC;
    padding: 0.8em 1em 0.6em 1em;
    transition: 0.1s ease-in-out;

    &:hover {
        filter: saturate(1.5);
        transition: 0.1s ease-in-out;
    }

    @include mobile-or-smaller {
        height: 61px;
        width: 100%;
        position: fixed;
        display: none;
        top: 118px;
        left: 0;
        margin-bottom: 0;
        text-align: center;
        box-shadow: 0 3px 6px rgba(0,0,0,0.1);
        text-decoration: underline;
        font-size: 24px;
    }
}
