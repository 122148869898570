$text-highlight-color: #A1FBEC;
$text-color: white;
$dark-text-color: #242424;
$background-color: white;
$header-background-color: white;
$footer-text-color: #242424;
$down-arrow-spacing: 5vh;
$down-arrow-size: 10vh;
$down-arrow-width: $down-arrow-size * 2;
$down-arrow-panel-bg-color: white;
$down-arrow-bg-color: black;
$down-arrow-pulse-color: rgba($down-arrow-bg-color, 0.5);
$down-arrow-panel-pulse-color: rgba($down-arrow-panel-bg-color, 0.5);
$down-arrow-icon-size: 4vh;
$down-arrow-icon-margin: 4vh;
$down-arrow-icon-pushed-margin: 6vh;
