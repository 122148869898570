.jobs-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.jobs-container {
    max-width: 1400px;
}

.open-positions-header {
    //margin-top: 120px;
    padding: 3em 8vw;
    color: $dark-text-color;

    @include tablet-or-smaller {
        padding: 2em 8vw;
    }
    
    p {
        font-size: 16px;
        margin-top: 1em;
        font-family: 'Roboto', sans-serif;
    }
}

.open-positions {
    padding: 0 8vw;
    color: $dark-text-color;
    font-family: 'Roboto', sans-serif;

    @media only screen and (max-width: 335px) {
        padding: 0;
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
    }
    
    .subheading {
        font-size: 16px;
        color: #B2B2B2;
    }
}

.job-listing {
    margin: 1em 0;
    //background-color: red;
    padding: 40px;
    border: solid 1px #B2B2B2;

    a {
        text-decoration: none;
        font-family: 'Josefin Sans', sans-serif;
        color: $dark-text-color;
    }

    @include mobile-or-smaller {
        flex-direction: column;
        padding: 5vw;
    }
}

.job-listing-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @include mobile-or-smaller {
        flex-direction: column;
        align-items: flex-start;
    }
}

.jobs #header {
    height: 50vh;

    .down-arrow {
        display: none;
    }
}

.jobs .banner-image {
    height: 100%;
}

.apply {
    background-color: #A1FBEC;
    padding: 0.8em 1em 0.6em 1em;
    transition: 0.1s ease-in-out;

    &:hover {
        filter: saturate(1.5);
        transition: 0.1s ease-in-out;
    }

    position: relative;
    right: 0;
    font-size: 16px;

    p {
        color: $dark-text-color;
    }

    @include mobile-or-smaller {
        margin-top: 2em;
        width: 100%;
        text-align: center;
    }
}

.job-copy {
    section {
        font-size: 16px;
        padding: 1em;
    
        li {
            list-style: disc;
        }
    }

    p {
        font-size: 16px;
    }
}

.job-copy .about-you {
    padding-left: 0;
}

.job-details {
    margin: 40px 0 0 0;
}

.start-date {
    padding-bottom: 1em;
}

.jobs .jobs-button {
    @include mobile-or-smaller {
        display: none;
    }
}

.jobs .banner-image {
    @include mobile-or-smaller {
        background-position: center 120px;
    }
}