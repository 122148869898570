*{
    box-sizing: border-box;
}

html,
body{
    margin: 0;
    font-family: "Montserrat", sans-serif;
    background-color: $background-color;
    color: $text-color;
    overflow-x: hidden;
    font-size: 3vh;
}

html {
    scroll-behavior: smooth;
}

h2 {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 32px;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

.horizontal-line {
    width: 225px;
    height: 2px;
    background: $text-highlight-color;
    margin: 1em;

    @include tablet-only {
        margin: 3em;
    }

    @include tablet-or-smaller {
        width: 40%;
    }
}

.tablet-or-smaller {

    display: none;

    @include tablet-or-smaller {
        display: block;
    }
   
}

.tablet-or-bigger {

    display: none;

    @include tablet-or-bigger {
        display: block;
    }

}

.mobile-only {
    display: none;

    @include mobile-or-smaller {
        display: unset;
    }
}