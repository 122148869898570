footer{
    @extend %panel;
    position: relative;
    background: $header-background-color;
    display: flex;
    flex-direction: column;
    //justify-content: ;
    font-family: 'Roboto', sans-serif;
    font-size: 80%;
    align-items: center;
    height: auto;

    .logo{
        height: 40vh;
        flex: 0 0 auto;
    }

    h2,
    p{
        text-align: center;
        margin: 0;
        flex: 0 0 auto;
        color: $footer-text-color;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    h2 {
        //font-size: 5vh;
        //text-transform: lowercase;

        @include tablet-or-smaller {
            font-size: 32px;
        }
    }

    /*.copyright{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 50%;
        color: rgba($footer-text-color, 0.5);
    }*/

    @include tablet-or-bigger {
        font-size: 140%;
    }

    #contact {

        margin: 3em 0 2em 0;

        @include mobile-or-smaller {
            max-width: 80%;
        }

        p {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            margin-top: 0.5em;
        }
    }

    .nav-logo {
        background: transparent url("../images/logos/pups_902x282.png") no-repeat 50% 50%;
        background-size: contain;
        height: 110px;
        width: 350px;

        @include tablet-or-smaller {
            height: 22vmin;
            width: 70vmin;
        }
    }

    .legal {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3em;

        p {
            font-size: 14px;
            margin-top: 14px;
        }
    }

    .email {
        @media only screen and (max-width: 335px) {
            font-size: 5vw;
        }
    }
}
